


[ant-click-animating-without-extra-node]:after {
  -webkit-animation: none;
  animation: none;
}
button.ant-btn.pyxis-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1.2rem 1.6rem;
  width: auto;
  height: 4rem;
  border-radius: 0.4rem;
}
button.ant-btn.pyxis-btn .btn-txt {
  height: 1.6rem;
  left: 0rem;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.6rem;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0rem 0rem;
}
button.ant-btn.ant-btn-primary.pyxis-btn {
  background-color: #7335cb;
  border-color: #7335cb;
}
button.ant-btn.ant-btn-primary.pyxis-btn .btn-txt {
  color: #ffffff;
}
button.ant-btn.ant-btn-default.pyxis-btn {
  border-color: #7335cb;
}
button.ant-btn.ant-btn-default.pyxis-btn .btn-txt {
  color: #7335cb;
  background-color: transparent;
}
button.ant-btn.ant-btn-link.pyxis-btn .btn-txt {
  color: #7335cb;
}
button.ant-btn.pyxis-btn.btn-xl {
  width: 45.4rem;
  height: 5rem;
  padding: 0rem 0rem;
}
button.ant-btn.pyxis-btn.btn-md {
  width: 11.8rem;
  height: 4rem;
  padding: 1.2rem 1.6rem;
}
button.ant-btn.pyxis-btn.btn-lg {
  width: 22.4rem;
  height: 4rem;
  padding: 1.2rem 1.6rem;
}
button.ant-btn.ant-btn-primary.pyxis-btn:active,
button.ant-btn.ant-btn-primary.pyxis-btn:focus {
  background-color: #552796;
  border-color: #552796;
}
button.ant-btn.ant-btn-primary.pyxis-btn:hover {
  background-color: #986bd9;
  border-color: #986bd9;
}
button.ant-btn.ant-btn-primary.pyxis-btn[disabled],
button.ant-btn.ant-btn-primary.pyxis-btn[disabled]:hover {
  background-color: #dac9f1;
  border-color: #dac9f1;
}
button.ant-btn.ant-btn-default.pyxis-btn:active,
button.ant-btn.ant-btn-default.pyxis-btn:focus {
  border-color: #552796;
}
button.ant-btn.ant-btn-default.pyxis-btn:hover {
  border-color: #986bd9;
}
button.ant-btn.ant-btn-default.pyxis-btn[disabled],
button.ant-btn.ant-btn-default.pyxis-btn[disabled]:hover {
  border-color: #dac9f1;
}
button.ant-btn.ant-btn-default.pyxis-btn:active .btn-txt,
button.ant-btn.ant-btn-default.pyxis-btn:focus .btn-txt,
button.ant-btn.ant-btn-link.pyxis-btn:active .btn-txt,
button.ant-btn.ant-btn-link.pyxis-btn:focus .btn-txt {
  color: #552796;
}
button.ant-btn.ant-btn-default.pyxis-btn:hover .btn-txt,
button.ant-btn.ant-btn-link.pyxis-btn:hover .btn-txt {
  color: #986bd9;
}
button.ant-btn.ant-btn-default.pyxis-btn[disabled] .btn-txt,
button.ant-btn.ant-btn-default.pyxis-btn[disabled]:hover .btn-txt,
button.ant-btn.ant-btn-link.pyxis-btn[disabled] .btn-txt,
button.ant-btn.ant-btn-link.pyxis-btn[disabled]:hover .btn-txt {
  color: #dac9f1;
}



a.ant-dropdown-trigger.ant-dropdown-link.pyxis-dropdown {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.6rem;
  display: flex;
  align-items: center;
  text-align: center;
  display: inline-block;
  /* Core colors/Pyxis Blue */
  color: #152370;
  flex: none;
  order: 0;
  flex-grow: 0;
}
a.ant-dropdown-trigger.ant-dropdown-link.pyxis-dropdown svg {
  width: 1.2rem;
  margin-left: 0.3rem;
  fill: #152370;
}

.ant-menu.pyxis-menu li.ant-menu-item.ant-menu-item-only-child {
  /* OS/16/Semibold-CAP */
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.4rem;
  text-transform: uppercase;
  /* Main/L0 */
  color: #152370;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.ant-menu.pyxis-menu li.ant-menu-item:hover,
.ant-menu.pyxis-menu li.ant-menu-item-selected {
  border-bottom: 0.4rem solid #12C8B2;
  border-radius: 0.4rem;
}







.label.ant-checkbox-wrapper.pyxis-checkbox {
  width: 1.6rem;
  height: 1.6rem;
  left: 0rem;
  top: 0rem;
  /* Accent Colors/Soft Green */
  border-radius: 0.2rem;
  /* Inside Auto Layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0.8rem 0rem;
  border: 0.1rem solid #cfd5f6;
}
label.ant-checkbox-wrapper.ant-checkbox-wrapper-checked.pyxis-checkbox span.ant-checkbox.ant-checkbox-checked .ant-checkbox-input:focus + .ant-checkbox-inner {
  border: 0.1rem solid #12c8b2;
}
label.ant-checkbox-wrapper.ant-checkbox-wrapper-checked.pyxis-checkbox span.ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
  border: 0.1rem solid #12c8b2;
}
.pyxis-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #12c8b2;
  border-color: #12c8b2;
}
.pyxis-checkbox .ant-checkbox-checked::after {
  border: 0.1rem solid #12c8b2;
}



input.ant-input.pyxis-input {
  background: #f7f8fe;
  border: 0.1rem solid transparent;
  box-sizing: border-box;
  border-radius: 0.4rem;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 0rem;
  width: -webkit-fill-available;
  height: 5.6rem;
  font-family: 'Inter';
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  color: #152370;
}
input.ant-input.pyxis-input:hover,
input.ant-input.pyxis-input:focus {
  border: 0.1rem solid #7335cb;
}
input.ant-input.pyxis-input::-moz-placeholder {
  font-family: 'Inter';
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  color: #8090e7;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0.2rem 0rem;
}
input.ant-input.pyxis-input:-ms-input-placeholder {
  font-family: 'Inter';
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  color: #8090e7;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0.2rem 0rem;
}
input.ant-input.pyxis-input::placeholder {
  font-family: 'Inter';
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  color: #8090e7;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0.2rem 0rem;
}
input.ant-input.pyxis-input:focus {
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

span.ant-input-affix-wrapper.ant-input-password.pyxis-password {
  background: #f7f8fe;
  border: 0.1rem solid transparent;
  box-sizing: border-box;
  border-radius: 0.4rem;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 0rem;
  width: -webkit-fill-available;
  height: 5.6rem;
}
span.ant-input-affix-wrapper.ant-input-password.pyxis-password:hover {
  border: 0.1rem solid #7335cb;
}
span.ant-input-affix-wrapper.ant-input-affix-wrapper-focused.ant-input-password.pyxis-password,
span.ant-input-affix-wrapper.ant-input-password.pyxis-password:focus {
  border: 0.1rem solid #7335cb;
  box-shadow: none;
}
span.ant-input-affix-wrapper.ant-input-password.pyxis-password input::-moz-placeholder {
  height: 2.4rem;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 2.4rem;
  display: flex;
  align-items: center;
  color: #8090e7;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0.2rem 0rem;
}
span.ant-input-affix-wrapper.ant-input-password.pyxis-password input:-ms-input-placeholder {
  height: 2.4rem;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 2.4rem;
  display: flex;
  align-items: center;
  color: #8090e7;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0.2rem 0rem;
}
span.ant-input-affix-wrapper.ant-input-password.pyxis-password input::placeholder {
  height: 2.4rem;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 2.4rem;
  display: flex;
  align-items: center;
  color: #8090e7;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0.2rem 0rem;
}
span.ant-input-affix-wrapper.ant-input-password.pyxis-password:focus {
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}
.pyxis-password input.ant-input {
  background-color: #f7f8fe;
}


section.ant-layout.ant-layout-has-sider.pyxis-layout {
  text-align: center;
}
section.ant-layout.ant-layout-has-sider.pyxis-layout .ant-layout-sider-children {
  background: #152370;
  color: #FFFFFF;
}
section.ant-layout.ant-layout-has-sider.pyxis-layout header.ant-layout-header,
section.ant-layout.ant-layout-has-sider.pyxis-layout main.ant-layout-content,
section.ant-layout.ant-layout-has-sider.pyxis-layout footer.ant-layout-footer {
  /* Fill/White */
  background: #FFFFFF;
  /* Main */
  box-shadow: 0rem 0rem 0.2rem rgba(30, 21, 83, 0.2);
}







































[ant-click-animating-without-extra-node]:after {
  -webkit-animation: none;
  animation: none;
}
button.ant-btn.pyxis-social-btn {
  width: 17rem;
  height: 5.6rem;
  background: #3b5998;
  border-radius: 0.8rem;
  display: flex;
  padding: 0rem;
  border: 0rem;
}
button.ant-btn.pyxis-social-btn > span:nth-child(1) {
  width: 4.2rem;
  height: 4.2rem;
  border-radius: 0.4rem;
  margin: 0.7rem 0rem 0.7rem 0.8rem;
  background-color: #ffffff;
}
button.ant-btn.pyxis-social-btn > span:nth-child(1) > span {
  margin: 0.9rem;
}
button.ant-btn.pyxis-social-btn > span:nth-child(1) > span > svg {
  height: 2.4rem;
  width: 2.4rem;
  fill: #3b5998;
}
button.ant-btn.pyxis-social-btn > span:nth-child(2) {
  padding: 1.6rem 0rem 1.6rem 1.5rem;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #ffffff;
}
button.ant-btn.pyxis-social-btn.social-sm {
  width: 2.4rem;
  height: 2.4rem;
}
button.ant-btn.pyxis-social-btn.social-sm > span:nth-child(1) {
  margin: 0rem;
}
button.ant-btn.pyxis-social-btn.social-lg {
  width: 36rem;
}
button.ant-btn.pyxis-social-btn.social-lg > span:nth-child(2) {
  padding: 1.6rem 0rem 1.6rem 3.8rem;
}
button.ant-btn.pyxis-social-btn.social-twitter {
  background-color: #03a9f4;
}
button.ant-btn.pyxis-social-btn.social-twitter > span:nth-child(1) > span > svg {
  fill: #03a9f4;
}
button.ant-btn.pyxis-social-btn.social-instagram {
  background-color: #c13584;
}
button.ant-btn.pyxis-social-btn.social-instagram > span:nth-child(1) > span > svg {
  fill: #c13584;
}
button.ant-btn.pyxis-social-btn.social-linkedin {
  background-color: #0077b5;
}
button.ant-btn.pyxis-social-btn.social-linkedin > span:nth-child(1) > span > svg {
  fill: #0077b5;
}
button.ant-btn.pyxis-social-btn.social-google {
  background-color: #4783f1;
}
button.ant-btn.pyxis-social-btn.social-google > span:nth-child(1) > span > svg {
  fill: #4783f1;
}
button.ant-btn.pyxis-social-btn.social-instagram.social-lg > span:nth-child(2) {
  padding: 1.6rem 0rem 1.6rem 3.7rem;
}
button.ant-btn.pyxis-social-btn.social-linkedin.social-lg > span:nth-child(2) {
  padding: 1.6rem 0rem 1.6rem 4.4rem;
}
button.ant-btn.pyxis-social-btn.social-twitter.social-lg > span:nth-child(2),
button.ant-btn.pyxis-social-btn.social-google.social-lg > span:nth-child(2) {
  padding: 1.6rem 0rem 1.6rem 4.9rem;
}


